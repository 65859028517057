import Button from '../../../components/UI/Button/Button';
import Table from '../../../components/Table/Table';
import Loading from '../../../components/Loading/Loading';
import Notification from '../../../components/UI/Notification/Notification';
import { TIMETABLE_DISPLAY_FIELDS } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import companyDetails from '../../companyDetails';

const fields = TIMETABLE_DISPLAY_FIELDS.filter((elm) => ['date', 'time', 'name', 'duration'].includes(elm.name)).concat(
  [{ name: 'spaces', label: 'Space' }]
);

const LocationsPage = ({
  settings = { showUpcomingEvents: false, upcomingEventsClassTypes: [] },
  upcomingEvents = [],
  upcomingEventsIsLoading = false,
  upcomingEventsIsError = false,
  gymData = null,
  isCityNameCorrect = false
}) => {
  const navigate = useNavigate();
  const hero = <img id="hero-img" className="homepage_hero" src={require('../../assets/hero-image.jpg')} alt="hero" />;
  const title = (
    <div className="mx-8 text-5xl mt-[-400px] xl:mx-0 md:mt-[-300px]  md:w-[57%] xl:text-6xl text-white font-bold ">
      Welcome To {gymData.gymName} At Silkstream
    </div>
  );
  const loginSignUpCard = (
    <div className="mt-[30px] mx-8 md:mr-8 md:ml-auto md:w-[40%] md:mt-[-70px] relative p-8 bg-primary text-white  ">
      <div className="text-2xl pb-2 border-b-2 border-white mb-4">Sign Up To {gymData.gymName}!</div>
      <div className="mb-8">Register and complete your virtual induction to start using the gym at Silkstream.</div>

      <Button label={'sign up'} onClick={() => navigate(`/gym/${gymData.gymId}/signup`)} secondary fullWidth />
    </div>
  );
  const incorrectGymName = (
    <div className="mt-[30px] mx-8 md:mr-8 md:ml-auto md:w-[40%] md:mt-[-70px] relative p-8 bg-primary text-white text-center ">
      <div className="text-2xl pb-2 border-b-2 border-white mb-4"> URL ADDRESS IS INCORRECT</div>
      <div className="mb-8">Please check the URL address or contact us for more information.</div>
      <Button
        label={'CONTACT US'}
        onClick={() => (window.location.href = `mailto:${companyDetails.email}`)}
        secondary
        fullWidth
      />
    </div>
  );

  const upcomingEventsTable =
    isCityNameCorrect && settings.showUpcomingEvents ? (
      upcomingEventsIsLoading ? (
        <Loading />
      ) : upcomingEventsIsError ? (
        <Notification defaultMsg />
      ) : (
        <div className="m-8">
          <div className={'uppercase text-3xl font-bold'}>Upcoming events:</div>
          <Table fields={fields.map((elm) => elm.name)} showHideFieldsBtn data={upcomingEvents} />
        </div>
      )
    ) : (
      <div className="h-[100px]"></div>
    );
  return (
    <div>
      <div className="homepage_header">
        {hero}
        <div className={'homepage_body_container'}>
          {title}
          {isCityNameCorrect ? loginSignUpCard : incorrectGymName}
          {upcomingEventsTable}
        </div>
      </div>
    </div>
  );
};

export default LocationsPage;
