import { useContext } from 'react';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/App/AppContext';

const cards = [
  {
    title: 'Gym',
    description: 'Access top-of-range cardiovascular and resistance equipment.'
  },
  {
    title: 'Studio',
    description: 'Make use of the private studio space, train on your own or with friends!'
  },
  {
    title: 'Guidance',
    description: 'Watch your virtual induction video for key guidance on using available equipment.'
  },
  {
    title: 'Personal Training',
    description: 'Contact motive8 to be connected with a personal trainer – click here to find out more.',
    children: (
      <Button
        className={'p-6 absolute bottom-0 w-full'}
        secondary
        fullWidth
        label={'Contact'}
        onClick={() => window.open('https://eu.jotform.com/form/240952907085361')}
      />
    )
  }
];

const Homepage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);
  const hero = <img id="hero-img" className="homepage_hero" src={require('../../assets/hero-image.jpg')} alt="hero" />;

  const loginSignUpCard = (
    <div className="mx-8 md:mr-8 md:ml-auto md:w-[40%] mt-[-180px] relative p-8 bg-primary text-white">
      <div className="text-2xl pb-2 border-b-2 border-white mb-4">Sign up!</div>
      <div className="mb-8">
        If you have already signed up then please log in below, for details on signing up please contact us.
      </div>
      <Button
        label={isAuthenticated ? 'dashboard' : 'login'}
        onClick={() => navigate(isAuthenticated ? `/gym` : `/login`)}
        secondary
        fullWidth
      />
    </div>
  );
  const cardsContainer = (
    <div className="mt-20 sm:flex justify-center flex-wrap mb-14 gap-8">
      {cards.map(({ title, description, children = null }, i) => (
        <div key={i} className="text-center mb-8 w-full sm:mb-0 md:w-[250px] bg-neutral-200 relative pb-20 md:pb-20 ">
          <div className="py-6 text-white text-lg font-bold bg-secondary">{title}</div>
          <div className="pb-10 sm:pb-0 px-6 pt-10">{description}</div>
          {children ? children : null}
        </div>
      ))}
    </div>
  );
  return (
    <div className="homepage_header">
      {hero}
      <div className="homepage_body_container">{loginSignUpCard}</div>

      <div className="mx-8 md:w-3/4 md:mx-auto">
        <div className="font-bold my-10">
          Reinvigorate your body and mind inside your high-end private leisure facility
        </div>
        <div className="mx-8">{cardsContainer}</div>
      </div>
    </div>
  );
};

export default Homepage;
