import Title from '../../../components/UI/Title/Title';
const PrivatePolicyPage = () => (
  <div className="body-container text-justify">
    <Title title={'PRIVACY POLICY'} />
    <section id="introduction">
      <div className="sectionTitle">INTRODUCTION</div>
      <div>
        Residential Management Group Limited respects your privacy and is committed to protecting your personal data.
        This privacy notice will inform you as to how we look after your personal data when you visit our website
        (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.
        <br />
        <br />
        Please also use the Glossary to understand the meaning of some of the terms used in this privacy notice.
        <br />
        <br />
        Residential Management Group Limited is part of the Places for People Group, one of the largest property and
        leisure management organisations in the UK. Places for People provides customers with a wide range of products
        and services, including leisure centre and gym memberships, affordable finance products and retirement services
        to name just a few. We would like to occasionally send you marketing information on products and services that
        we think will be of interest to you and we will ask for your consent to do this. We promise to treat your
        contact details with the utmost care, and to never pass them to companies outside of the Places for People
        Group.
      </div>
    </section>

    <div className="sectionTitle">CONTENTS</div>
    <div>
      <ul className={'oList'}>
        <li>
          <a href="#introduction">Introduction</a>
        </li>
        <li>
          <a href="#important-info">Important Information and Who We Are</a>
        </li>
        <li>
          <a href="#data-we-collect">The Data We Collect About You</a>
        </li>
        <li>
          <a href="#data-collection-methods">How Is Your Personal Data Collected?</a>
        </li>
        <li>
          <a href="#data-usage">How We Use Your Personal Data</a>
        </li>
        <li>
          <a href="#data-disclosures">Disclosures Of Your Personal Data</a>
        </li>
        <li>
          <a href="#international-transfers">International Transfers</a>
        </li>
        <li>
          <a href="#data-security">Data Security</a>
        </li>
        <li>
          <a href="#data-retention">Data Retention</a>
        </li>
        <li>
          <a href="#legal-rights">Your Legal Rights</a>
        </li>
        <li>
          <a href="#lawful-basis">Glossary Lawful Basis</a>
        </li>
      </ul>
    </div>

    <section id="important-info">
      <div className="sectionTitle">IMPORTANT INFORMATION AND WHO WE ARE</div>
      <div className="sectionSubTitle">Purpose of this privacy notice</div>
      <ul className="no-bullets">
        <li>
          This privacy notice aims to give you information on how Residential Management Group Limited (“RMG”) collects
          and processes your personal data through your use of this website, including any data you may provide through
          this website.
        </li>
        <br />
        <li>This website is not intended for children and we do not knowingly collect data relating to children.</li>
        <br />
        <li>
          RMG is a residential managing agent. RMG acts for and on behalf of its clients. If you are an existing
          customer of RMG you will have a contractual relationship with one of our clients. Your personal data will be
          processed by RMG in accordance with that client’s privacy policy. RMG will be acting as data processor in
          those circumstances. If you are a customer of RMG and you wish to obtain a copy of the privacy policy that
          applies to that processing then please contact: centralcompliance@rmguk.com setting out your name and the
          property you own.
        </li>
        <br />
        <li>
          If you are not an existing customer of RMG then it is important that you read this privacy notice together
          with any other privacy notice or fair processing notice we may provide on specific occasions when we are
          collecting or processing personal data about you so that you are fully aware of how and why we are using your
          data. This privacy notice supplements the other notices and is not intended to override them.
        </li>
      </ul>
      <div className="sectionSubTitle">Controller</div>
      <ul className="no-bullets">
        <li>
          Residential Management Group Limited is the controller and responsible for your personal data (collectively
          referred to as "RMG", "we", "us" or "our" in this privacy notice).
        </li>
        <br />
        <li>
          If you have any questions about this privacy notice, including any requests to exercise your legal rights,
          please contact the Compliance Director using the details set out below.
        </li>
      </ul>

      <div className="sectionSubTitle">Contact details</div>
      <ul className="no-bullets">
        <li>Our full details are:</li>
        <br />
        <li>Full name of legal entity: Residential Management Group Limited (CRN: 01513643)</li>
        <br />
        <li>Name or title of data privacy manager: Chris Alexander, Legal and Compliance Director</li>
        <br />
        <li>Email address: centralcompliance@rmguk.com</li>
        <br />
        <li>Postal address: RMG House, Essex Road, Hoddesdon, EN11 0DR</li>
        <br />
        <li>Telephone number: 0345 002 4444</li>
        <br />
        <li>
          You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK
          supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to
          deal with your concerns before you approach the ICO so please contact us in the first instance.
        </li>
      </ul>

      <div className="sectionSubTitle">Changes to the privacy notice and your duty to inform us of changes</div>
      <ul className="no-bullets">
        <li>
          This version was last updated on 7th August 2024 and previous versions can be obtained by contacting us.
        </li>
        <br />
        <li>
          It is important that the personal data we hold about you is accurate and current. Please keep us informed if
          your personal data changes during your relationship with us.
        </li>
      </ul>
      <div className="sectionSubTitle">Third-party links</div>
      <ul className="no-bullets">
        <li>
          This website may include links to third-party websites, plug-ins and applications. Clicking on those links or
          enabling those connections may allow third parties to collect or share data about you. We do not control these
          third-party websites and are not responsible for their privacy statements. When you leave our website, we
          encourage you to read the privacy notice of every website you visit.
        </li>
      </ul>
    </section>

    <section id="data-we-collect">
      <div className="sectionTitle">THE DATA WE COLLECT ABOUT YOU</div>

      <ul className="no-bullets">
        <li>
          Personal data, or personal information, means any information about an individual from which that person can
          be identified. It does not include data where the identity has been removed (anonymous data).
        </li>
      </ul>
      <br />

      <ul className="no-bullets">
        <li>
          We may collect, use, store and transfer different kinds of personal data about you which we have grouped
          together follows:
          <ul className={'uList'}>
            <li>
              Identity Data includes first name, maiden name, last name, username or similar identifier, marital status,
              title, date of birth and gender.
            </li>
            <li>Contact Data includes billing address, delivery address, email address and telephone numbers.</li>
            <li>Financial Data includes bank account and payment card details.</li>
            <li>
              Transaction Data includes details about payments to and from you and other details of products and
              services you have purchased from us.
            </li>
            <li>
              Technical Data includes internet protocol (IP) address, your login data, browser type and version, time
              zone setting and location, browser plug-in types and versions, operating system and platform and other
              technology on the devices you use to access this website.
            </li>
            <li>
              Usage Data includes information about how you use our website, products and services. We do not collect
              any Special Categories of Personal Data about you (this includes details about your race or ethnicity,
              religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
              membership, information about your health and genetic and biometric data). Nor do we collect any
              information about criminal convictions and offences.
            </li>
          </ul>
        </li>
      </ul>

      <div className="sectionSubTitle">If you fail to provide personal data</div>
      <ul className="no-bullets">
        <li>
          Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail
          to provide that data when requested, we may not be able to perform the contract we have or are trying to enter
          into with you (for example, to provide you with goods or services). In this case, we may have to cancel a
          product or service you have with us but we will notify you if this is the case at the time.
        </li>
      </ul>
    </section>

    <section id="data-collection-methods">
      <div className="sectionTitle">HOW IS YOUR PERSONAL DATA COLLECTED?</div>

      <ul className="no-bullets">
        <li>
          We use different methods to collect data from and about you including through:
          <ul className={'uList'}>
            <li>
              Direct interactions. You may give us your Identity, Contact and Financial Data by filling in forms or by
              corresponding with us by post, phone, email or otherwise. This includes personal data you provide when
              you:
              <ul className={'uList'}>
                <li>apply for our products or services;</li>
                <li>subscribe to our service;</li>
                <li>request marketing to be sent to you;</li>
                <li>give us some feedback.</li>
              </ul>
            </li>
            <li>
              Automated technologies or interactions. As you interact with our website, we may automatically collect
              Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using
              cookies, and other similar technologies. We may also receive Technical Data about you if you visit other
              websites employing our cookies. Please see our{' '}
              <a href="https://www.rmguk.com/cookie-policy/" className="border-black border-b-[1px] border-solid">
                Cookie Policy : Residential Management Group (rmguk.com)
              </a>{' '}
              for further details
            </li>
            <li>
              Third parties or publicly available sources. We may receive personal data about you from various third
              parties and public sources as set out below:
              <ul className={'uList'}>
                <li>
                  Technical Data from the following parties:
                  <ul className={'uList'}>
                    <li>analytics providers such as Google based outside the EU</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <section id="data-usage">
      <div className="sectionTitle">HOW WE USE YOUR PERSONAL DATA</div>
      <ul className="no-bullets">
        <li>
          We will only use your personal data when the law allows us to. Most commonly, we will use your personal data
          in the following circumstances:
          <ul className={'uList'}>
            <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
            <li>
              Where it is necessary for our legitimate interests (or those of a third party) and your interests and
              fundamental rights do not override those interests.
            </li>
            <li>Where we need to comply with a legal or regulatory obligation.</li>
          </ul>
        </li>
        <li>Generally we do not rely on consent as a legal basis for processing your personal data.</li>
      </ul>
      <div className="sectionSubTitle">Marketing</div>
      <ul className="no-bullets">
        <li>
          We do not use your personal data for marketing purposes except in a business to business context where we have
          assessed it is necessary for our legitimate interests and it does not impact on your fundamental rights and
          freedoms.{' '}
        </li>
      </ul>
      <div className="sectionSubTitle">Cookies</div>
      <ul className="no-bullets">
        <li>
          You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access
          cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible
          or not function properly. For more information about the cookies we use, please see RMG’s Cookie Policy.
        </li>
      </ul>
      <div className="sectionSubTitle">Change of Purpose </div>
      <ul className="no-bullets">
        <li>
          We will only use your personal data for the purposes for which we collected it, unless we reasonably consider
          that we need to use it for another reason and that reason is compatible with the original purpose. If you wish
          to get an explanation as to how the processing for the new purpose is compatible with the original purpose,
          please contact us.
        </li>
        <br />
        <li>
          If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
          legal basis which allows us to do so.
        </li>
        <br />
        <li>
          Please note that we may process your personal data without your knowledge or consent, in compliance with the
          above rules, where this is required or permitted by law.
        </li>
      </ul>
    </section>

    <section id="data-disclosures">
      <div className="sectionTitle">DISCLOSURES OF YOUR PERSONAL DATA</div>
      <ul className="no-bullets">
        <li>We may have to share your personal data with:</li>
        <ul className={'uList'}>
          <li>
            other companies in the Places for People Group and who are based in the United Kingdom and provide IT and
            system administration services and undertake leadership reporting.{' '}
          </li>
          <li>
            Service providers acting as processors based in the EEA who provide IT and system administration services.
          </li>
          <li>
            Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and
            insurers based in the EEA who provide consultancy, banking, legal, insurance and accounting services.
          </li>
          <li>
            HM Revenue & Customs, regulators, professional bodies and other authorities acting as processors or joint
            controllers based in the United Kingdom who require reporting of processing activities in certain
            circumstances.
          </li>
          <li>
            third parties third parties to whom we may choose to sell, transfer, or merge parts of our business or our
            assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to
            our business, then the new owners may use your personal data in the same way as set out in this privacy
            notice.
          </li>
        </ul>
        <li>
          We require all third parties to respect the security of your personal data and to treat it in accordance with
          the law. We do not allow our third-party service providers to use your personal data for their own purposes
          and only permit them to process your personal data for specified purposes and in accordance with our
          instructions.{' '}
        </li>
      </ul>
    </section>

    <section id="international-transfers">
      <div className="sectionTitle">INTERNATIONAL TRANSFERS</div>
      <ul className="no-bullets">
        <li>
          We may transfer your personal data to service providers that carry out certain functions on our behalf. This
          may involve transferring personal data outside the UK to countries which have laws that do not provide the
          same level of data protection as the UK law.
        </li>
        <br />
        <li>
          Whenever we transfer your personal data out of the UK to service providers, we ensure a similar degree of
          protection is afforded to it by ensuring that the following safeguards are in place.
        </li>
        <br />
        <li>
          We may use specific standard contractual terms approved for use in the UK which give the transferred personal
          data the same protection as it has in the UK, namely the International Data Transfer Addendum to the European
          Commission’s standard contractual clauses for international data transfers
        </li>
      </ul>
    </section>

    <section id="data-security">
      <div className="sectionTitle">DATA SECURITY</div>
      <ul className="no-bullets">
        <li>We store personal information both electronically and in paper form.</li>
        <br />
        <li>
          We implement security policies, processes and technical security solutions to protect the personal information
          we hold from:
          <ul className={'uList'}>
            <li>Unauthorised access</li>
            <li>Improper use or disclosure</li>
            <li>Unauthorised modification</li>
            <li>Unlawful destruction or accidental loss</li>
          </ul>
        </li>
        <br />
        <li>
          We’ll do what we can to make sure we hold records about you (on paper and electronically) in a secure way, and
          we’ll only make them available to those who have a right to see them. Examples of our security include:
          <ul className={'uList'}>
            <li>
              Encryption, please refer to RMG’s Encryption Policy. For a copy please email: centralcompliance@rmguk.com
            </li>
            <li>
              Pseudonymisation, meaning that we’ll use a different name so we can hide parts of your personal
              information from view. This means that someone outside of RMG could work on your information for us
              without ever knowing it was yours.{' '}
            </li>
            <li>
              Controlling access to systems and networks allows us to stop people who are not allowed to view your
              personal information from getting access to it{' '}
            </li>
            <li>
              Training for our staff allows us to make them aware of how to handle information and how and when to
              report when something goes wrong
            </li>
            <li>
              Regular testing of our technology and ways of working including keeping up to date on the latest security
              updates (commonly called patches){' '}
            </li>
          </ul>
        </li>
        <br />
        <li>
          When you contact us, we may ask you to provide us with some information so that we can confirm your identity.
          If other people (e.g. family members, support workers, solicitors) act on your behalf we will take steps to
          ensure that you have agreed for them to do so. This may include asking them to provide us with supporting
          information to indicate your consent. We do this to protect you and to make sure that other people cannot find
          things out about you that they are not entitled to know.
        </li>
        <br />
        <li>
          Employees and third parties who have access to, or are associated with the processing of, your personal
          information are obliged to make reasonable efforts to safeguard it.
        </li>
        <br />
        <li>
          We have put in place procedures to deal with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do so.{' '}
        </li>
      </ul>
    </section>

    <section id="data-retention">
      <div className="sectionTitle">DATA RETENTION</div>
      <div className="sectionSubTitle">How long will you use my personal data for?</div>
      <ul className="no-bullets">
        <li>
          We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
          including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </li>
        <br />
        <li>
          To determine the appropriate retention period for personal data, we consider the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data and whether we can achieve those purposes
          through other means, and the applicable legal requirements.
        </li>
        <br />
        <li>
          Details of retention periods for different aspects of your personal data are available in our retention policy
          which you can request from us by contacting us centralcompliance@rmguk.com.
        </li>
        <br />
        <li>In some circumstances you can ask us to delete your data.</li>
        <br />
        <li>
          In some circumstances we may anonymise your personal data (so that it can no longer be associated with you)
          for research or statistical purposes in which case we may use this information indefinitely without further
          notice to you.
        </li>
      </ul>
    </section>

    <section id="legal-rights">
      <div className="sectionTitle">YOUR LEGAL RIGHTS</div>
      <ul className="no-bullets">
        <li>
          The law gives you a number of rights to control what personal information is used by us and how it is used by
          us.
        </li>
      </ul>
      <div className="sectionSubTitle">You can ask for access to the information we hold on you</div>
      <ul className="no-bullets">
        <li>
          You have the right to ask for the information we have about you. When we receive a request from you in
          writing, we must give you access to what personal information we’ve recorded about you.
        </li>
        <br />
        <li>
          However, we can’t let you see any parts of a record which contain:
          <ul className={'uList'}>
            <li>Confidential information about other people; or </li>
            <li>May be held in preparation to defend legal claims</li>
            <li>
              This applies to personal information that is in both paper and electronic records. If you ask us, we’ll
              also let others see your record (except if one of the points above applies).{' '}
            </li>
            <br />
          </ul>
        </li>
        <li>
          A request for personal information can be made via email or in writing. This is known as a subject access
          request. In order to make a subject access request you will need to provide the following information:
          <ul className="no-bullets">
            <li>
              <ul className={'uList'}>
                <li>your name</li>
                <li>your address</li>
                <li>proof of identity and signature</li>
                <li>enough information to identify your records</li>
                <br />
              </ul>
            </li>
            <li>
              What types of documents could I submit as proof of ID?
              <ul className={'uList'}>
                <li>Copy passport with signature (please remove your passport number)</li>
                <li>Copy driving license picture with signature (please remove your driver number)</li>
                <li>Copy of signed tenancy or contract with us</li>
                <br />
              </ul>
            </li>
            <li>
              We will not start your subject access request until we are satisfied that you have provided us with enough
              information for us to identify you.
            </li>
            <br />
            <li>
              Once you have made a request you will receive an acknowledgement and your request should be answered
              within one month. In certain circumstances, we are allowed to take longer but we will tell you if we feel
              we may need longer without undue delay from when we receive your request.
            </li>
            <br />
            <li>
              If you can’t ask for your records in writing, we’ll make sure there are other ways that you can. If you
              have any queries about access to your information please contact 0345 002 4444.
            </li>
          </ul>
        </li>
      </ul>
      <div className="sectionSubTitle">You can ask to change information you think is inaccurate</div>
      <ul className="no-bullets">
        <li>You should let us know if you disagree with something we may have recorded about you.</li>
        <br />
        <li>
          We may not always be able to change or remove that information but we’ll correct factual inaccuracies and may
          include your comments in the record to show that you disagree with it.
        </li>
      </ul>
      <div className="sectionSubTitle">You can ask to delete information (right to erasure)</div>
      <ul className="no-bullets">
        <li>
          In some circumstances you can ask for your personal information to be deleted, for example:
          <ul className={'uList'}>
            <li>
              Where your personal information is no longer needed for the reason why it was collected in the first place{' '}
            </li>
            <li>
              Where you have removed your consent for us to use your information (where there is no other legal reason
              us to use it)
            </li>
            <li>Where there is no legal reason for the use of your information </li>
            <li>Where deleting the information is a legal required</li>
          </ul>
        </li>
        <li>
          Where your personal information has been shared with others, we’ll do what we can to make sure those using
          your personal information comply with your request for erasure.
        </li>
        <br />
        <li>
          Please note that we can’t delete your information where:
          <ul className={'uList'}>
            <li>we’re required to have it by law</li>
            <li>it is for historical research, or statistical purposes where it would make information unusable</li>
            <li>it is necessary for legal claims</li>
          </ul>
        </li>
      </ul>
      <div className="sectionSubTitle">You can ask to limit what we use your personal data for</div>
      <ul className="no-bullets">
        <li>You have the right to ask us to restrict what we use your personal information for where:</li>
        <ul className={'uList'}>
          <li>you have identified inaccurate personal information, and have told us of it</li>
          <li>
            where we have no legal reason to use that information but you want us to restrict what we use it for rather
            than erase the information altogether
          </li>
        </ul>
        <li>
          We will assess whether you have a right to a restriction and where restriction of use has been granted, we’ll
          inform you before we carry on using your personal information.
        </li>
        <br />
        <li>
          Where possible we’ll seek to comply with your request, but we may need to hold or use information because we
          are required to by law or we have a legal basis to do so, such as a contract.
        </li>
      </ul>
      <div className="sectionSubTitle">
        You can ask to have your information moved to another provider (data portability)
      </div>
      <ul className="no-bullets">
        <li>
          You have the right to ask for your personal information to be given back to you or another service provider of
          your choice in a commonly used format. This is called data portability.
        </li>
        <br />
        <li>
          However this only applies if we’re using your personal information with consent (not where we are processing
          your personal information for contractual, legitimate interests, legal obligations or vital interests as a
          legal basis) and if decisions were made by a computer and not a human being.
        </li>
        <br />
        <li>It’s likely that data portability won’t apply to most of the services you receive from RMG</li>
      </ul>
      <div className="sectionSubTitle">Right to understand Automated Decisions made about you</div>
      <ul className="no-bullets">
        <li>
          You can ask to have any computer made decisions explained to you, and details of how we may have ‘risk
          profiled’ you.
        </li>
        <br />
        <li>
          You have the right to question decisions made about you by a computer, unless it’s required for any contract
          you have entered into, required by law, or you’ve consented to it.{' '}
        </li>
        <br />
        <li>
          You also have the right to object if you are being ‘profiled’. Profiling is where decisions are made about you
          based on certain things in your personal information, e.g. your health.
        </li>
        <br />
        <li>
          If and when your personal information is used to profile you, in order to deliver the most appropriate service
          to you, you will be informed.
        </li>
        <br />
        <li>
          If you have concerns regarding automated decision making, or profiling, please contact the Compliance Director
          at centralcompliance@rmguk.com who’ll be able to advise you about how we are using your information.
        </li>
        <br />
        <li>
          In order to pursue any of the above data rights you can write to us at the following address:
          <ul className="no-bullets">
            <br />
            <li>Central Compliance</li>
            <li>Residential Management Group Limited</li>
            <li>RMG House </li>
            <li>Essex Road </li>
            <li>Hoddesdon</li>
            <li>EN11 0DR </li>
          </ul>
        </li>
        <li>
          Alternatively email us at centralcompliance@rmguk.com. (Please ensure you attach your identity documents with
          the document reference numbers removed).
        </li>
        <br />
        <li>
          You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However,
          we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively,
          we may refuse to comply with your request in these circumstances.
        </li>
      </ul>
    </section>

    <section id="lawful-basis">
      <div className="sectionTitle">GLOSSARY LAWFUL BASIS</div>
      <ul className="no-bullets">
        <li>
          Legitimate Interest means the interest of our business in conducting and managing our business to enable us to
          give you the best service/product and the best and most secure experience. We make sure we consider and
          balance any potential impact on you (both positive and negative) and your rights before we process your
          personal data for our legitimate interests. We do not use your personal data for activities where our
          interests are overridden by the impact on you (unless we have your consent or are otherwise required or
          permitted to by law). You can obtain further information about how we assess our legitimate interests against
          any potential impact on you in respect of specific activities by contacting us
        </li>
        <br />
        <li>
          Performance of Contract means processing your data where it is necessary for the performance of a contract to
          which you are a party or to take steps at your request before entering into such a contract.{' '}
        </li>
        <br />
        <li>
          Comply with a legal or regulatory obligation means processing your personal data where it is necessary for
          compliance with a legal or regulatory obligation that we are subject to.
        </li>
      </ul>
    </section>
  </div>
);
export default PrivatePolicyPage;
