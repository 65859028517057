import Icon from '../../../components/UI/Icon/Icon';
import Title from '../../../components/UI/Title/Title';
import companyDetails from '../../companyDetails';
import { Link } from 'react-router-dom';
import GoogleMaps from '../../../components/GoogleMaps/GoogleMaps';

export default () => (
  <div className={'body-container '}>
    <Title title="Contact us" />
    <div className={'md:flex justify-between'}>
      <div>
        <div className="font-bold md:grid md:grid-cols-2 md:w-3/4 gap-4 text-md">
          <div>Mail:</div>
          <div className="text-secondary mb-6 md:mb-0">
            <a href={`mailto:${companyDetails.email}`}>{companyDetails.email}</a>
          </div>
          <div>Address:</div>
          <div className="mb-6 md:mb-0">
            RMG House <br />
            Essex Road <br />
            Hoddesdon, <br />
            EN11 0DR <br />
          </div>
          <div>Telephone number:</div>
          <div>0345 002 4444</div>
        </div>

        <div className="flex gap-x-3 my-14">
          {companyDetails.socialMedia.map(({ name, url }) => (
            <Link key={name} to={url} target="_blank" rel="noopener noreferrer">
              <Icon name={name} size={40} color={companyDetails.secondary} />
            </Link>
          ))}
        </div>
      </div>

      <GoogleMaps />
    </div>
  </div>
);
